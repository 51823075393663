<template>
  <BaseModal
    :loading="loading"
    :dialog="updateUserDialog"
    v-on:cancel="$emit('cancel')"
  >
    <template v-slot:title>
      Update User
    </template>
    <template v-slot:body>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.firstName"
          :counter="450"
          :error-messages="errors.collect('firstName')"
          label="First Name"
          data-vv-name="firstName"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-account"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.lastName"
          :counter="450"
          :error-messages="errors.collect('lastName')"
          label="Last Name"
          data-vv-name="lastName"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-account"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.title"
          :counter="450"
          :error-messages="errors.collect('title')"
          label="Title"
          data-vv-name="title"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-format-title"
        ></v-text-field>

        <v-text-field
          v-validate="'required|max:450'"
          v-model="request.email"
          :counter="450"
          :error-messages="errors.collect('email')"
          label="Email"
          data-vv-name="email"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-at"
        ></v-text-field>
        <v-divider />
        <v-switch
          label="Update Password?"
          color="red"
          v-model="request.updatePassword"
        ></v-switch>
        <template v-if="request.updatePassword">
          <v-text-field
            v-model="request.password"
            :counter="200"
            :error-messages="errors.collect('password')"
            label="Password"
            data-vv-name="password"
            required
            v-validate="request.updatePassword ? 'required|max:200' : ''"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="() => (show1 = !show1)"
            color="secondary"
            outlined
            prepend-icon="mdi-lock"
          ></v-text-field>
        </template>
        <v-divider class="pb-5" />
        <v-text-field
          v-validate="'required|max:14'"
          v-model="request.phoneNumber"
          :counter="14"
          v-mask="phoneMask"
          :error-messages="errors.collect('phoneNumber')"
          label="Phone Number"
          data-vv-name="phoneNumber"
          required
          color="secondary"
          outlined
          prepend-icon="mdi-phone"
        ></v-text-field>
        <v-select
          v-validate="'required'"
          data-vv-name="select"
          :items="availableRoles"
          clearable
          v-model="request.userRole"
          item-value="name"
          item-text="name"
          label="Select a user role"
          color="secondary"
          outlined
          prepend-icon="mdi-account-lock-outline"
        />
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="submit"
        large
        color=""
        depressed
        class="v-btn--text primary--text"
        :disabled="!valid"
        :loading="loading"
        submit
        >submit</v-btn
      >
      <v-btn
        @click="assignPropsToRequestModel"
        color=""
        depressed
        class="v-btn--text error--text"
        :disabled="loading"
        >Reset</v-btn
      >
    </template>
  </BaseModal>
</template>

<script>
import services from "@/services";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "userUpdate",
  props: {
    user: {
      type: Object,
      default: null,
    },
    updateUserDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    show1: false,
    valid: false,
    phoneMask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ") ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    request: {
      userId: "",
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      password: "",
      phoneNumber: "",
      userRole: "",
      updatePassword: false,
    },
    dictionary: {
      attributes: {
        email: "E-mail Address",
        // custom attributes
      },
      custom: {
        name: {
          required: () => "Name can not be empty",
          max: "The name field may not be greater than 10 characters",
          // custom messages
        },
        select: {
          required: () => "Select field is required",
        },
      },
    },
  }),
  computed: {
    availableRoles() {
      return this.$store.state.admin.availableRoles;
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      let that = this;
      this.request.userId = this.$props.user.user.id;

      await services.adminService
        .updateUser(this.request)
        .then((response) => {
          if (response) {
            that.$store.commit(
              "snack/set_snack",
              "User update successful!"
            );
            that.$emit("userWasUpdated");
          } else {
            that.$store.commit("snack/set_snack", "User update failed.");
          }
        })
        .catch(() => {
          that.$store.commit("snack/set_snack", "User update failed.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clear() {
      this.request.firstName = "";
      this.request.lastName = "";
      this.request.title = "";
      this.request.email = "";
      this.request.phoneNumber = "";
      this.request.password = "";
      this.request.userRole = "";
      this.request.updatePassword = false;
      this.$validator.reset();
    },
    assignPropsToRequestModel() {
      var userModel = this.$props.user.user;
      if (userModel) {
        this.request.firstName = userModel.firstName;
        this.request.lastName = userModel.lastName;
        this.request.title = userModel.title;
        this.request.email = userModel.email;
        this.request.phoneNumber = userModel.phoneNumber;
        this.request.password = "";
        this.request.userRole = this.$props.user.userRoles[0].name;
        this.request.userId = userModel.id;
        this.request.updatePassword = false;
      }
    },
    getAvailableRoles() {
      this.loading = true;
      this.$store
        .dispatch("admin/get_available_roles")
        .finally(() => (this.loading = false));
    },
  },
  mounted: function() {
    this.$validator.localize("en", this.dictionary);
    this.assignPropsToRequestModel();
    this.getAvailableRoles();
  },
};
</script>
